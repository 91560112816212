import React, { Component } from 'react'

const demo_src="https://www.static112233.com/m/assets/images/mobile/xbet247/logo-xbet247.webp";
class SpanImage extends Component {

  render() {
    
    return (
        <img src={this.props.url??demo_src} alt="DisplayImage" width={this.props.width} height={this.props.height} className="displayimage"/>
    );
  }
}
export default  SpanImage;