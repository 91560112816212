import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import SpanImage from './SpanImage';


export default class List extends Component {
  render() {
    return (
        <li>
        <Link to={this.props.href}>
            {(this.props.url!=='')?
            <SpanImage url={this.props.url} 
            width={this.props.width} 
            height={this.props.height}/>
            :''}
            {this.props.text}
        </Link>
    </li>
    )
  }
}
