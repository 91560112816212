import React from 'react';
import List from './List'

export default function Navigation() {
  return (
    <div id="navigation">
         <ul className="menubar" style={{paddingBottom:"15px"}}>           
            <List href="/home" url="/images/rooms.png" width="15px" height="15px" text=""/>
            <List href="/siteowner" url="/images/configuration.png" width="15px" height="15px" text="সাইট ওনার"/>
            <List href="/services" url="/images/help.png" width="15px" height="15px" text="কাষ্টমার সার্ভিস"/>
            <List href="/admin" url="" width="15px" height="15px" text="এডমিন"/>
            <List href="/subadmin" url="" width="15px" height="15px" text="সাব এডমিন"/>
            <List href="/supersub" url="" width="15px" height="15px" text="সুপার সাব"/>
            <List href="/superadmin" url="" width="15px" height="15px" text="সুপার"/>
            <List href="/master" url="" width="15px" height="15px" text="মাস্টার"/>    
       </ul>
    </div>
  )
}
