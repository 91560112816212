// import React from "react";

// function Rating() {
//   const rates = 7;
//   const rate_span = '<span class="fa fa-star checked"></span>';
//   for (let ii = 0; ii < rates; ii++) {
//     // const element = array[ii];
//     rate_span += '<span class="fa fa-star checked"></span>';
//   }
//     return (<td>{rate_span}</td>);
  
// }

// export default Rating;
import React, { Component } from 'react'

const rate_span='';

export default class Rating extends Component {
  render() {
    return (
        <td>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
        
        </td>
    )
  }
}

