import React, { useState, useEffect } from "react";
import SpanImage from "../comp/SpanImage";
import { useParams, Link } from "react-router-dom";
import Navigation from "../comp/Navigation";
import HomeElement from "../comp/HomeElement";
import SearchForm from "../comp/SearchForm";
import HeadScroll from "../comp/HeadScroll";
import Agent, { AgentBody } from "../comp/Agent";
import VELKI_APP_NAME,{ VELKI_API_URL, VELKI_API_KEY } from "../config/Constant";

export default function Search() {
  const [admindata, setAdmindata] = useState({ admindata: {} });
  let { agentId } = useParams();

  const fetchDetaild = () => {
    fetch(`${VELKI_API_URL}/apiv1/?agent_id=${agentId}`)
      .then((res) => res.json())
      .then((items) => setAdmindata(items));
  };

  useEffect(() => {
    fetchDetaild();
    // console.log('fetched');
  },[]);

  // console.log("Admindata.status===200");
  // console.log(admindata.status);
  if (admindata.status === 200) {
    return (
      <div id="wrapper">
        <span>
          <Link to="/">{
            /* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */
            <SpanImage width="360" height="100" /> 
            }
            <h1>{VELKI_APP_NAME}-এজেন্ট লিস্ট</h1></Link>
        </span>
        <Navigation/>
        <HeadScroll/>
        
        <SearchForm/>       
       
        <div style={{ minHeight: "50px" }}>
          <div>Search Results</div>
          <table className="data" style={{ width: "95%" }}>
            <thead>
              <Agent
                type="Type"
                name="Name"
                id="Id No"
                app="App Link"
                phone="Phone Number"
                uplink="Complain"
              />
            </thead>
            <tbody>
              {admindata.data.map((item) => (
                <AgentBody
                  key={item.id}
                  keyid={item.id}
                  rowcls="odd"
                  type={(() => {
                    switch (item.agent_type) {
                      case 'site_admin':
                        return 'Site Admin'
                      case 'customer_service':
                        return 'Customer Service'
                      case 'super_admin':
                        return 'Super'
                      case 'super_sub':
                        return 'Super Sub'
                      case 'master_admin':
                        return 'Master'
                      default:
                        return 'Customer'
                    }
                  })()}
                  //{item.agent_type}
                  name={item.agent_name}
                  id={item.id}
                  fb={item.agent_fb}
                  app={item.agent_phone}
                  phone={item.agent_phone}
                  uplink={item.agent_upline}
                />
              ))}
            </tbody>
          </table>
        </div>
        

      </div>
    );
  } else{
    return (
      <>
        <div id="wrapper">
          <span>
            <Link to="/">
              <h1>{VELKI_APP_NAME}-এজেন্ট লিস্ট</h1>
              {/* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */}
            </Link>
          </span>
          <Navigation />
          <SearchForm/>
          <HomeElement
            title="Loading"
            head="No Record Found"
            desc="Data Loading../Connection failed.."
          />
        </div>
      </>
    );
  }

  // return (
  //   <>
  //     <div id="wrapper">
  //       <span>
  //         <Link to="/">
  //           <h1>{VELKI_APP_NAME}-এজেন্ট লিস্ট</h1>
  //           {/* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */}
  //         </Link>
  //       </span>
  //       <Navigation />
        
  //     </div>
  //   </>
  // );
}
