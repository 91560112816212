import React, { Component } from "react";
import Marquee from "react-fast-marquee";

const newsdetails=`🔥 Full Cassino Premium site... 🔥agentlist.me🔥যারা এজেন্ট নিতে আগ্রহি দ্রুত যোগাযোগ করুন।`;
const wanum="+8801746950900";

class HeadScroll extends Component {
  render() {
    return (
      <Marquee pauseOnHover="true" direction="left" 
        id="marquee"
        classsName="bg-light"
        scrollamount="{this.props.scrollamount}"
        behavior="{this.props.behavior}"        
      >
        {this.props.news??newsdetails}  &nbsp; <a href={'https://wa.me/'+wanum} target='_blank'>{wanum}</a>&nbsp;
      </Marquee>
    );
  }
}
export default HeadScroll;
