import React, { Component } from "react";

{
  /* <HomeElement title="" head="" desc=""></HomeElement> */
}

export default class HomeElement extends Component {
  render() {
    return (
      <div id="dashboard-contents">
        <div id="licenseinfo">
            <div className="header">
              <div className="title">{(this.props.title)?this.props.title:''}</div>
              <div className="legend">{(this.props.head)?this.props.head:''}</div>
            </div>
            <div className="body">
              <div className="code" dangerouslySetInnerHTML={{__html: (this.props.desc)?this.props.desc:''}}>
                
              </div>
            </div>
          </div>        
      </div>
    );
  }
}


