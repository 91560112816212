import React, { Component } from "react";
import Whatsapp from "./Whatsapp";
import Facebook from "./Facebook";
import PhoneCall from "./PhoneCall";
import ComplainLink from "./ComplainLink";
import Rating from "./Rating";

class Agent extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          {this.props.type ? <th align="center">{this.props.type}</th> : ""}
          {this.props.name ? <th align="center">{this.props.name}</th> : ""}
          {this.props.id ? <th align="center">{this.props.id}</th> : ""}
          {this.props.rating ? <th align="center">{this.props.rating}</th> : ""}
          {this.props.app ? <th align="center">{this.props.app}</th> : ""}
          {this.props.phone ? <th align="center">{this.props.phone}</th> : ""}
          {this.props.uplink ? <th align="center">{this.props.uplink}</th> : ""}
        </tr>
      </>
    );
  }
}

class AgentBody extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          {this.props.type ? <td align="center">{this.props.type}</td> : ""}
          {this.props.name ? <td align="center">{this.props.name}</td> : ""}
          {this.props.id ? <td align="center">{this.props.id}</td> : ""}
          {this.props.rating ? <Rating rating={this.props.rating} /> : ""}
          <td align="center">
            {/* <Whatsapp phone={this.props.phone} width="25"/> */}
            {this.props.phone ? (
              <Whatsapp phone={this.props.phone} width="25" />
            ) : (
              ""
            )}
            {this.props.fb ? <Facebook fb={this.props.fb} width="25" /> : ""}
          </td>
          {this.props.phone ? (
            <td align="center">
              <PhoneCall phone={this.props.phone} />
            </td>
          ) : (
            ""
          )}
          {this.props.phone ? (
            <td align="center">
              <ComplainLink url={this.props.type + "/" + this.props.uplink} />
            </td>
          ) : (
            ""
          )}
        </tr>
      </>
    );
  }
}

export default Agent;
export { AgentBody };