import React, { Component } from "react";
import {VELKI_API_URL,VELKI_API_KEY,VELKI_HOME_URL} from '../config/Constant';

export default class SearchForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      isloading: false,
    };     
  }
 
  handlesChange = (e) => {
    this.setState({ name: e.target.value });
    console.log(this.state.name);    
  };

  handleSubmit = (event) => {
    event.preventDefault();         
    // console.log("handleSubmit");
    // console.log(this.state.name);
    let url = `/search/${this.state.name}`;    
    window.location.href=url;  
       
  };

  render() {
    return (
      <>
        <div style={{ minHeight: "30px" }}>
          <form onSubmit={this.handleSubmit} className="example">
            <label>
              <input
                type="text"
                value={this.state.name}
                onChange={this.handlesChange}
                placeholder="Search Agent ID.."
                className="search_text"
              />
            </label>
            {/* <input type="submit" value="Search"/> */}
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>
      </>
    );
  }
}
