import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class ComplainLink extends Component {
  render() {
    return (
   
      <>
        {/* <a href={`complain/${this.props.url}`} target="_blank">Complain</a> */}
        <Link  to={`/complain/${this.props.url}`} target="_blank">
          Complain
        </Link>
        </>
       

    )
  }
}
