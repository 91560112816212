import React, { Component } from "react";
import SpanImage from "../comp/SpanImage";
import { Link } from "react-router-dom";
import Navigation from "../comp/Navigation";
import Agent, { AgentBody } from "../comp/Agent";
import HomeElement from "../comp/HomeElement";
import HeadScroll from "../comp/HeadScroll";

import VELKI_APP_NAME, {
  VELKI_API_URL,
  VELKI_API_KEY,
} from "../config/Constant";

let datarules = [
  {
    title: "কিভাবে একাউন্ট খুলবেনঃ",
    head: "",
    desc: `agentlist.me তে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে
    হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি
    যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন
    করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে
    লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া জরুরী।`,
  },
  {
    title: "এজেন্ট লিস্টঃ",
    head: "",
    desc: `একাউন্ট খুলতে নিম্বের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। 
    এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর মাধ্যমে যোগাযোগ করতে হবে। 
    হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। 
    হোয়াটসাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা 
    ফোন নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন। 
    হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে 
    থেকে ইন্সটল করে নিন। <br/>
    <a href="https://agentlist.me/master">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a> `,
  },
  {
    title: "এজেন্ট কয় প্রকারঃ",
    head: "",
    desc: `<a href="https://agentlist.me/superadmin">অনলাইন সুপার এজেন্ট লিস্টঃ</a>
     সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে পারেন। <br/>
     কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে জানাতে হবে। 
     উপরে মেনু তে <a href="https://agentlist.me/admin">এডমিন লিষ্ট</a> দেয়া আছে।
     <a href="https://agentlist.me/master"> অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a>
       অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। 
       কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি <a href="https://agentlist.me/superadmin">সুপার এজেন্ট </a>এর কাছে অভিযোগ 
       করতে হবে। <br/>
       <a href="https://facebook.com/">বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন। </a> <br/><br/>
       লোকাল <a href="https://agentlist.me/master">মাষ্টার এজেন্ট লিস্টঃ</a> 
       <br/>লোকাল মাষ্টার এজেন্ট রা,
        শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। <br/>
        কিন্তু তাদের সাথে লেনদেন প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। 
        তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না।  <br/>
        লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা পরিচিত দের সাথে লেনদেন করে । 
        যারা আগে বাজি ধরিয়ে দিত, তাদের কেই মুলত লোকাল এজেন্ট দেয়া হয়েছে। 
        লোকাল এজেন্ট রা অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন রাখতে চায়। 
        লোকাল এজেন্ট দের সাথে অনলাইনে কোন ভাবেই লেনদেন করবেন না, 
        আর করে থাকলে তার দায়ভার পুরোটাই আপনার।`,
  },
  {
    title: "OFFICIAL WEBSITE",
    head: "",
    desc: `<a href="https://agentlist.me">{${VELKI_APP_NAME}}</a>`,
  },
];

let admindata = [
  {
    id: "0",
    agent_type: "Site Admin",
    agent_name: "Agent Name",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];
console.log(datarules);
//"http://localhost/agent/apiv1/?agent_type=site_admin";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
      datarules: datarules,
    };
  }

  async componentDidMount() {
    console.log("cdm");
    console.log(this.state.datarules);
    let a = this.state.datarules;
    let url = `${VELKI_API_URL}/apiv1/?agent_type=master_admin`;
    let res = await fetch(url);
    let parsedData = await res.json();
    if (parsedData.data) {
      this.setState({
        agents: parsedData.data,
        isloading: false,
        datarules: a,
      });
    }
    // console.log("Parsed");
    // console.log(parsedData.data);
  }

  render() {
    const { agents, isloading, datarules } = this.state;
    // console.log("Render Agents:");
    // console.log(typeof(agents));
    // console.log(agents[0]);
    // console.log(isloading);

    return (
      <div id="wrapper">
        <span>
          <Link to="/">
            {
              /* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */
              <SpanImage               
                width="360"
                height="100"
              />
            }
            <h1>{VELKI_APP_NAME}-এজেন্ট লিস্ট</h1>
          </Link>
        </span>
        <Navigation/>
        <HeadScroll/>
        <div className="dashboard-contents">
          <div id="licenseinfo">
            <div className="header">
              <div className="title">কুইক মাস্টার এজেন্ট নম্বর:</div>
              <div className="legend"></div>
            </div>
            <div className="body">
              <div className="code">
                <div style={{ minHeight: "50px", width: "630px" }}>
                  <table className="data" style={{ width: "95%" }}>
                    <thead>
                      <td className="blackHeading" colspan="9">
                        {VELKI_APP_NAME}
                      </td>
                    </thead>
                    <thead>
                      <Agent
                        type="Type"
                        name="Name"
                        id="Id No"
                        app="App Link"
                        phone="Phone Number"
                        uplink="Complain"
                      />
                    </thead>
                    <tbody>
                      {agents.slice(0, 1).map((item) => (
                        <AgentBody
                          key={item.id}
                          keyid={item.id}
                          rowcls="odd"
                          type={(() => {
                            switch (item.agent_type) {
                              case "site_admin":
                                return "Site Admin";
                              case "customer_service":
                                return "Customer Service";
                              case "super_admin":
                                return "Super";
                              case "super_sub":
                                return "Super Sub";
                              case "master_admin":
                                return "Master";
                              default:
                                return "Customer";
                            }
                          })()}
                          name={item.agent_name}
                          id={item.id}
                          fb={item.agent_fb}
                          app={item.agent_phone}
                          phone={item.agent_phone}
                          uplink={item.agent_upline}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <HomeElement
          title="কিভাবে একাউন্ট খুলবেনঃ"
          head="Head"
          desc="agentlist.me তে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া জরুরী।"
        /> */}

        {datarules.map((item, index) => (
          <HomeElement
            key={index}
            title={item.title}
            head={item.head}
            desc={item.desc}
          />
        ))}
      </div>
    );
  }
}

class Home2 extends Component {
  componentDidMount() {    
    const externalWebsiteURL = "https://api.agentlist.me";
    window.location.href = externalWebsiteURL;
  }

  render() {    
    return <>Loading...</>;
  }
}
export { Home2 };
